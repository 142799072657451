import { default as _404O9RUCBSYT4Meta } from "F:/OSPanel/domains/go-vue.local/client/pages/404.vue?macro=true";
import { default as index5eA7XBP2dlMeta } from "F:/OSPanel/domains/go-vue.local/client/pages/index.vue?macro=true";
import { default as privatertK9SJzMRtMeta } from "F:/OSPanel/domains/go-vue.local/client/pages/private.vue?macro=true";
import { default as _91id_93sQXuvYv4boMeta } from "F:/OSPanel/domains/go-vue.local/client/pages/project/[id].vue?macro=true";
import { default as projectRlpVmKz4fiMeta } from "F:/OSPanel/domains/go-vue.local/client/pages/project.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("F:/OSPanel/domains/go-vue.local/client/pages/404.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("F:/OSPanel/domains/go-vue.local/client/pages/index.vue")
  },
  {
    name: "private",
    path: "/private",
    meta: privatertK9SJzMRtMeta || {},
    component: () => import("F:/OSPanel/domains/go-vue.local/client/pages/private.vue")
  },
  {
    name: "project",
    path: "/project",
    component: () => import("F:/OSPanel/domains/go-vue.local/client/pages/project.vue"),
    children: [
  {
    name: "project-id",
    path: ":id()",
    component: () => import("F:/OSPanel/domains/go-vue.local/client/pages/project/[id].vue")
  }
]
  }
]