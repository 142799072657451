import validate from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/nuxt@3.13.2_eslint@8.48.0_rollup@4.24.0_sass@1.80.3_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/nuxt@3.13.2_eslint@8.48.0_rollup@4.24.0_sass@1.80.3_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-only": () => import("F:/OSPanel/domains/go-vue.local/client/middleware/admin-only.ts"),
  "guest-only": () => import("F:/OSPanel/domains/go-vue.local/client/middleware/guest-only.ts"),
  "user-only": () => import("F:/OSPanel/domains/go-vue.local/client/middleware/user-only.ts")
}