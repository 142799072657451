import revive_payload_client_qkRuOkc35M from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/nuxt@3.13.2_eslint@8.48.0_rollup@4.24.0_sass@1.80.3_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gP8vNfjzTB from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/nuxt@3.13.2_eslint@8.48.0_rollup@4.24.0_sass@1.80.3_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MuPcjQPds1 from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/nuxt@3.13.2_eslint@8.48.0_rollup@4.24.0_sass@1.80.3_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_RyKHMnJPiH from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/nuxt@3.13.2_eslint@8.48.0_rollup@4.24.0_sass@1.80.3_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Nk5OyJXsbi from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/nuxt@3.13.2_eslint@8.48.0_rollup@4.24.0_sass@1.80.3_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_V8otb4lnQj from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/nuxt@3.13.2_eslint@8.48.0_rollup@4.24.0_sass@1.80.3_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_EypyGXdU0v from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/nuxt@3.13.2_eslint@8.48.0_rollup@4.24.0_sass@1.80.3_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_s7ONPaycw9 from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "F:/OSPanel/domains/go-vue.local/client/.nuxt/components.plugin.mjs";
import prefetch_client_bJirKJFUPM from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/nuxt@3.13.2_eslint@8.48.0_rollup@4.24.0_sass@1.80.3_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_25jTxu8UgD from "F:/OSPanel/domains/go-vue.local/client/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import auth_vT9JWWT9pN from "F:/OSPanel/domains/go-vue.local/client/plugins/auth.ts";
import socket_client_N9cHubToyQ from "F:/OSPanel/domains/go-vue.local/client/plugins/socket.client.js";
export default [
  revive_payload_client_qkRuOkc35M,
  unhead_gP8vNfjzTB,
  router_MuPcjQPds1,
  payload_client_RyKHMnJPiH,
  navigation_repaint_client_Nk5OyJXsbi,
  check_outdated_build_client_V8otb4lnQj,
  chunk_reload_client_EypyGXdU0v,
  plugin_vue3_s7ONPaycw9,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bJirKJFUPM,
  i18n_25jTxu8UgD,
  auth_vT9JWWT9pN,
  socket_client_N9cHubToyQ
]