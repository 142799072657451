<script lang="ts" setup></script>
<template>
  <Html lang="en">
    <Head>
      <Title>Frontend developer, Vue.js, Nuxt</Title>
      <Meta
        name="description"
        content="Опытный Front-end разработчик vue.js, NUXT, golang, с огромным опытом верстки более 16 лет."
      />
    </Head>

    <Body>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </Body>
  </Html>
</template>
